import React, { Suspense } from "react";
import Loading from "~modules/_modules/Component/Loading";

// export const UserContextProvider = React.lazy(async () => {
//     const module = await import('./user/user.context.js');
//     // return
// });

// export const UserProvider = React.lazy(() => import("./user/user.context.js"));

// export const AppConfigProvider = React.lazy(() =>
//     import("./config/config.context.js")
// );

import UserProvider from './user/user.context'
// import MenuProvider from './menu/menu.context'
import AppConfigProvider from './config/config.context'

export const MenuProvider = React.lazy(() => import("./menu/menu.context.js"));

export default function AllProviders({ children }) {
    return (
        <Suspense fallback={<Loading />}>
            <UserProvider>
                <AppConfigProvider>
                    <MenuProvider>{children}</MenuProvider>
                </AppConfigProvider>
            </UserProvider>
        </Suspense>
    );
}
