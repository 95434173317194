import { crud, http } from '@mpk/react/services';

class CrudService extends crud {

    get(query={}){
        return http.request({
          method: http.methods.GET,
          url: `${this.path}`,
          query,
          options: this.options,
          config: this.config
        })
    }

    putOne(data){
      return http.request({
        method: http.methods.PUT,
        url: `${this.path}`,
        data,
        options: this.options,
        config: this.config
      })
    }

    getDefault(query={}){
      return http.request({
        method: http.methods.GET,
        url: `${this.path}`,
        query,
        options: this.options,
        config: this.config
      })
    }

    getOne(id){
      return http.request({
        method: http.methods.GET,
        url: `${this.path}/${id}`,
        // query,
        options: this.options,
        config: this.config
      })
    }

    getSpt(id){
      return http.request({
        method: http.methods.GET,
        url: `/spt/${id}`,
        // query,
        options: this.options,
        config: this.config
      })
    }

    getBySpt(query={}){
      return http.request({
        method: http.methods.GET,
        url: `${this.path}/spt`,
        query,
        options: this.options,
        config: this.config
      })
  }

}

export default CrudService;