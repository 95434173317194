import { http } from "@mpk/react/services";
import CrudService from "~services/crudService";

class AccessService extends CrudService {
    constructor() {
        super("/api/user-svc/menu");
    }

    /**
     * 
     * @param {map} query 
     * @returns {import("axios").AxiosPromise<AccessMenuDTO[]>}
     */
    get(query={}) {
        return super.get(query);
    }

    findAllRoleAccess() {
        return http.get(this.path + "/role");
    }

    /**
     *
     * @param {RoleAccessForm} form
     *
     */
    updateRoleAccessBulk(form) {
        return http.request({
            method: http.methods.PUT,
            url: this.path + "/role",
            data: form,
        });
    }

    /**
     *
     * @param {string} userId
     * @returns {import("axios").AxiosPromise<string[]>}
     */
    getUserAccess(userId) {
        return http.request({
            method: http.methods.GET,
            url: this.path + "/user/" + userId,
        });
    }
    
    /**
     *
     * @param {string} userId
     * @returns {import("axios").AxiosPromise<string[]>}
     */
    updateUserAccess(userId, form) {
        return http.request({
            method: http.methods.PUT,
            url: this.path + "/user/" + userId,
            data: form,
        });
    }
    resetUserAccess(userId) {
        return http.request({
            method: http.methods.DELETE,
            url: this.path + "/user/" + userId,
        });
    }
}

const accessService = new AccessService();
export default accessService;
