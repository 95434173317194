
/**
 * 
 * @param {any} o 
 * @returns {o is null}
 */
export function isNull(o) {
    return ({}).toString.call(o) === '[object Null]';
}

/**
 * 
 * @param {*} o 
 * @returns {o is undefined}
 */
export function isUndef(o) {
    return typeof o === 'undefined';
}

/**
 * @template T
 * @param {T} o 
 * @returns {o is Exclude<T, null | void | undefined>}
 */
export function isDefined(o) {
    return !isNull(o) && !isUndef(o);
}

/**
 * 
 * @param {any} o 
 * @returns {o is string}
 */
export function isString(o) {
    return (typeof o === 'string') || o instanceof String;
}

/**
 * 
 * @param {any} o 
 * @returns {o is number}
 */
export function isNumber(o) {
    return (typeof o === 'number') || o instanceof Number;
}

/**
 * 
 * @param {any} o 
 * @returns {o is boolean}
 */
export function isBoolean(o) {
    return typeof o === 'boolean' || o instanceof Boolean;
}

/**
 * 
 * @param {any} o 
 * @returns {o is string|number|boolean}
 */
export function isPrimitive(o) {
    return isDefined(o) && (isNumber(o) || isString(o) || isBoolean(o));
}