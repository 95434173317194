export const ROLE_ADMIN = 'YWRtaW4=';
export const ROLE_ADMIN_DECD = 'admin';

export const ROLE_SUPERVISOR = 'c3VwZXJ2aXNvcg==';
export const ROLE_SUPERVISOR_DECD = 'supervisor';

export const ROLE_USER = 'dXNlcg==';
export const ROLE_USER_DECD = 'user';

export const ROLE_ADMIN_COMPANY = 'YWRtaW5fY29tcGFueQ==';
export const ROLE_ADMIN_COMPANY_DECD = 'admin_company';

export const ROLES_OPTIONS = [
    { label: ROLE_ADMIN_DECD, value: ROLE_ADMIN },
    { label: ROLE_SUPERVISOR_DECD, value: ROLE_SUPERVISOR },
    { label: ROLE_USER_DECD, value: ROLE_USER },
    { label: ROLE_ADMIN_COMPANY_DECD, value: ROLE_ADMIN_COMPANY },
]