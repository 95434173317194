import CrudService from '../../../services/crudService';
import { http } from '@mpk/react/services';
import qs from "query-string";
import iziToast from 'izitoast';
import t from 'counterpart';
import httpService from '@mpk/react/services/http.service';
import DOMPurify from 'isomorphic-dompurify';

//
var service = null
var configUrl = ''
var urlFile = ''
if (window.emetconfiguration.direct) {
  configUrl = 'direct-svc'
  urlFile = 'document'
  service = new CrudService(`/api/${configUrl}/document`);
} else {
  configUrl = 'non-direct-svc'
  urlFile = 'upload'
  service = new CrudService(`/api/${configUrl}/upload`);
}

service.getMasterSignature = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/config/signer`,
    query
  })
}

service.getConfigKuota = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/config/quota`,
    query
  })
}

service.resetSetting = async (query) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/config/quota/regenerate`,
    query
  })
}

service.uploadFile = async (data) => {
  if (data.isSn) { urlFile = 'v2/document' }
  if (window.emetconfiguration.direct) {
    return http.request({
      method: http.methods.POST,
      url: `/api/${configUrl}/${urlFile}/upload`,
      data
    })
  } else {
    return http.request({
      method: http.methods.POST,
      url: `/api/${configUrl}/${urlFile}`,
      data
    })
  }
}

service.uploadFileSign = async (data, isVida) => {
  // if(data.isSn){ urlFile = 'v2/document' } //EMO-1910 upload sign tidak ada sn
  if (isVida) {
    return http.request({
      method: http.methods.POST,
      url: `/api/${configUrl}/v3/${urlFile}/upload/sign`,
      data
    })
  } else {
    if (window.emetconfiguration.direct) {
      return http.request({
        method: http.methods.POST,
        url: `/api/${configUrl}/${urlFile}/upload/sign`,
        data
      })
    } else {
      return http.request({
        method: http.methods.POST,
        url: `/api/${configUrl}/${urlFile}/sign`,
        data
      })
    }
  }
}

service.restampFile = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/${configUrl}/${urlFile}/restamp`,
    data
  })
}

service.downloadFile = async (data, merge) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/${configUrl}/download?merge=${merge}`,
    config: {
      // "content-type": "application/zip"
      responseType: "blob"
    },
    data
  })
}

service.getReport = async (data) => {
  console.log('GET REPORT V1');
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/usage-report?${qs.stringify(data)}`,
    config: {
      // "content-type": "application/zip"
      responseType: "blob"
    },
  })
}

service.getReportV3 = async (data) => {
  console.log('GET REPORT V3');
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/v3/usage-report?${qs.stringify(data)}`,
    config: {
      responseType: "blob"
    }
  })
    .then(async res => {
      const isJson = res.headers['content-type'].includes('application/json');

      /** @type {Blob} */
      const data = res.data;
      if (isJson) {
        res.data = JSON.parse(await data.text());
        return res;
      }
      else return res;
    })
}

// service.downloadRaw = async(id)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/api/${configUrl}/download/raw/${id}`,
//     config: {
//       responseType: "blob"
//     },
//   })
// }

// service.downloadStamp = async(id)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/api/${configUrl}/download/${id}`,
//     config: {
//       responseType: "blob"
//     },
//   })
// }

service.approve = async (ids) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/${configUrl}/${urlFile}/approve`,
    data: ids
  })
}

service.reject = async (ids, message) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/${configUrl}/${urlFile}/reject`,
    data: {
      ids: ids,
      message: message
    }
  })
}

service.restamp = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/restamp/${id}`,
    config: {
      timeout: 1000 * 60,
    }
  })
}

service.restampSn = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/restamp-new-sn/${id}`,
    config: {
      timeout: 1000 * 60,
    }
  })
}

service.downloadPreview = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/download/preview/${id}`,
    config: {
      responseType: "arraybuffer"
    },
  })
}

service.downloadRaw = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/download/raw/${id}`,
    config: {
      responseType: "arraybuffer"
    },
  })
}

service.downloadStamp = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/download/${id}`,
    config: {
      responseType: "arraybuffer"
    },
  })
}

service.getMaster = async (id) => {
  if (window.emetconfiguration.direct) {
    return http.request({
      method: http.methods.GET,
      url: `/api/${configUrl}/doc`,
    })
  } else {
    return http.request({
      method: http.methods.GET,
      url: `/api/${configUrl}/master/document`,
    })
  }
}

service.getDetail = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/${id}`,
  })
}

service.commitCor = async (data, id) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/${configUrl}/${urlFile}/commit/${id}`,
    data
  })
}

service.reupload = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/reupload/${id}`
  })
}

service.hapus = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/api/${configUrl}/${urlFile}/${id}`,
  })
}

service.checkFile = async (checksum) => {
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/checksum`,
    query: {
      checksum: checksum
    }
  })
}

service.kirimEmail = async (id, email) => {
  try {
    email = email.slice(0, email.length - 1)
  } catch (e) { }
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/send/${id}?to=${email}`,
    // config: {
    //   params: {
    //     email: email
    //   }
    // },
  })
}

service.putRestamp = async (id, data) => {
  return http.request({
    method: http.methods.PUT,
    url: `/api/${configUrl}/${urlFile}/restamp/${id}`,
    data,
    config: {
      timeout: 1000 * 60,
    }
  })
}

service.getApprovalConfig = async (query) => {
  if (window.emetconfiguration.direct) {
    return http.request({
      method: http.methods.GET,
      url: `/api/direct-svc/config/application`,
      query
    })
  } else {
    return http.request({
      method: http.methods.GET,
      url: `/api/non-direct-svc/config/application`,
      query
    })
  }
}

// Tambahan Uci
service.getReportV3 = async (data) => {
  console.log('GET REPORT V3');
  return http.request({
    method: http.methods.GET,
    url: `/api/${configUrl}/${urlFile}/v3/usage-report?${qs.stringify(data)}`,
    config: {
      responseType: "blob"
    }
  })
    .then(async res => {
      const isJson = res.headers['content-type'].includes('application/json');

      /** @type {Blob} */
      const data = res.data;
      if (isJson) {
        res.data = JSON.parse(await data.text());
        return res;
      }
      else return res;
    })
}

service.reports = Object.freeze({
  listener(ev) {
    const data = ev.data;
    if (data.error) {
      iziToast.error({
        title: 'Fail to prepare Document Report',
        message: 'Fail to prepare document report'
      });
    }
    else {
      iziToast.question({
        title: 'Your reports ready',
        message: "Report compile success, begin download"
      });

      const exportUrl = `/api/${configUrl}/log/export`;
      httpService.get(exportUrl + '/download/' + data.id, {}, {
        responseType: "blob"
      })
        .then(res => {
          service.reports.downloadLink(res.headers.filename, res.data);
          // httpService.delete(exportUrl + "/" + data.id)
          //   .catch(err => { });
        })
        .catch(err => {
          console.error(err);
          const data = err.response?.data;
          iziToast.error(data?.detail || data?.message || err.message);
        });
    }
  },
  downloadLink(filename, blob, opt = { type: 'text/html' }) {
    const data = blob instanceof Blob ? blob : new Blob(blob, opt);

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}`); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
});

export default service;
