import React, { createContext, useContext, useMemo, useState } from "react";
import { useAuthorized } from "~context/user/user.context";
import userService from "~modules/_modules/User/User.service";
import errorService from "~services/errorService";

/** @type {React.Context<IAppConfigContext>} */
const AppConfigContext = createContext();

export default function AppConfigProvider({ children }) {
    /** @type {ReactState<map<AppConfigDTO>>} */
    const [configs, setConfigs] = useState({});

    const getConfigs = async () => {
        const configRes = await userService.getAppConfig();
        setConfigs(
            Object.fromEntries(
                configRes.data.map((config) => [config.name, {
                    id: config.id,
                    name: config.name,
                    value: config.value,
                    enabled: config.enabled
                }])
            )
        );
    };

    useAuthorized((user) => {
        getConfigs().catch((e) =>
            setTimeout(() => getConfigs().catch(errorService), 2000)
        );
    }, []);

    return (
        <AppConfigContext.Provider
            value={{
                configs: Object.values(configs),
                isEnabled(key) {
                    return !!configs[key]?.enabled;
                },
                isDisabled(key) {
                    return !configs[key]?.enabled;
                },
                getValue(key) {
                    return configs[key]?.value;
                },
                refresh() {
                    console.log("refresh app config");
                    getConfigs().catch(errorService);
                },
            }}
        >
            {children}
        </AppConfigContext.Provider>
    );
}

export function useEmetConfig() {
    return useContext(AppConfigContext);
}
