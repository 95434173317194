import CrudService from "../../../services/crudService";
import { http } from "@mpk/react/services";
import service from "../SingleSign/SingleSign.service";

// Tambahan Uci
var baseUrl = "group";
try {
    if (window.emetconfiguration.groupv2) baseUrl = "v2/group";
} catch (e) { }

class UserService extends CrudService {
    constructor() {
        super(`/api/user-svc/user`);
    }

    requestOtp = async (data) => {
        return http.request({
            method: http.methods.POST,
            url: `/api/user-svc/otp/request`,
            data
        })
    };

    validateOtp = async (data) => {
        return http.request({
            method: http.methods.POST,
            url: `/api/user-svc/otp/validate`,
            data
        })
    };

    getMembers = async (query) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/role/user`,
            query,
        });
    };

    getSupervisors = async (query) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/role/supervisor`,
            query,
        });
    };

    getActivation = async (query) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/direct-svc/config/activation/check`,
            query,
        });
    };

    getInfo = async (query) => {
        if (window.emetconfiguration.direct) {
            return http.request({
                method: http.methods.GET,
                url: `/api/direct-svc/info`,
                query,
            });
        } else {
            return http.request({
                method: http.methods.GET,
                url: `/api/non-direct-svc/info`,
                query,
            });
        }
    };

    /**
     *
     * @param {object} query
     * @returns {import("axios").AxiosPromise<AppConfigDTO[]>}
     */
    getAppConfig = async (query) => {
        if (window.emetconfiguration.direct) {
            return http.request({
                method: http.methods.GET,
                url: `/api/direct-svc/config/application`,
                query,
            });
        } else {
            return http.request({
                method: http.methods.GET,
                url: `/api/non-direct-svc/config/application`,
                query,
            });
        }
    };

    userInfo = async () => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/v1/user/info`,
        });
    };

    userInfoById = async (id) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/` + id,
        });
    };

    userUpdate = async (data) => {
        return http.request({
            method: http.methods.PUT,
            url: `/api/user-svc/user`,
            data,
        });
    };

    getSaldo = async () => {
        if (window.emetconfiguration.direct) {
            return http.request({
                method: http.methods.GET,
                url: `/api/direct-svc/saldo`,
            });
        } else {
            return http.request({
                method: http.methods.GET,
                url: `/api/non-direct-svc/saldo`,
            });
        }
    };

    getSaldoSn = async () => {
        if (window.emetconfiguration.direct) {
            return http.request({
                method: http.methods.GET,
                url: `/api/direct-svc/sn/saldo`,
            });
        } else {
            return http.request({
                method: http.methods.GET,
                url: `/api/non-direct-svc/sn/saldo`,
            });
        }
    };

    getSaldoMerged = async () => {
        const beService = (window.emetconfiguration.direct ? "" : "non-") + "direct-svc";
        const url = `/api/${beService}/info/saldo`;

        return http.request({
            method: http.methods.GET,
            url,
        });
    };

    getSaldoLimit = async (id) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/${baseUrl}/amount/${id}`,
        });
    };

    gantiPassword = async (data) => {
        return http.request({
            method: http.methods.POST,
            url: `/api/user-svc/user/change-password`,
            data,
        });
    };

    enabledUser = async (id) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/enable/${id}`,
        });
    };
    disabledUser = async (id) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/disable/${id}`,
        });
    };

    enableDisableUser = async (id, active = true) => {
        return http.request({
            method: http.methods.PUT,
            url: `/api/user-svc/user/active/${id}?active=${active}`,
        });
    };

    unlockUser = async (id) => {
        return http.request({
            method: http.methods.DELETE,
            url: `/api/user-svc/user/unlock/${id}`,
        });
    };

    addPermission = async (data) => {
        return http.request({
            method: http.methods.POST,
            url: `/api/user-svc/user/add/permission`,
            data,
        });
    };

    generateReport = async () => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/report`,
        });
    };

    getRegistrations = async (query = {}) => {
        return http.request({
            method: http.methods.GET,
            url: `/api/user-svc/user/manage/registration`,
            query,
        });
    };

    approveRegistrations = async (approved = false, approveForm = {}) => {
        return http.request({
            method: http.methods.PUT,
            url: `/api/user-svc/user/manage/registration/approve?approved=` + approved,
            data: approveForm,
        });
    };
}

const userService = new UserService();
export default userService;
